import React from 'react';
import { Menu } from 'antd';
import { Flags } from 'react-feature-flags';

import './index.less';

import SbButton from '../../../../components/common/SbButton';
import SbIcon from '../../../../components/common/SbIcon';
import SbContextMenu from '../../../../components/common/SbContextMenu';
import SbChannelIcon from '../../../../components/common/SbChannelIcon';
import { Channels, FeatureFlagNames } from '../../../../../constants';

interface IAddChannelButtonProps {
  buttonType?: 'primary' | 'secondary' | 'tertiary';
  onAddChannel: (channelType: Channels) => void;
}

const AddChannelButton: React.FC<IAddChannelButtonProps> = ({ buttonType, onAddChannel }) => {
  const onAddChannelClick = (channelType: Channels) => () => {
    onAddChannel(channelType);
  };

  const addChannelMenuContent = (
    <Menu className="sb-add-channel-button__menu">
      <Menu.Item key="telegram" onClick={onAddChannelClick(Channels.TELEGRAM)}>
        <div className="channel-icon">
          <SbChannelIcon size="small" type={Channels.TELEGRAM} />
        </div>
        <span>Telegram</span>
      </Menu.Item>
      <Menu.Item key="whats-app" onClick={onAddChannelClick(Channels.WHATSAPP)}>
        <div className="channel-icon">
          <SbChannelIcon size="small" type={Channels.WHATSAPP} />
        </div>
        <span>WhatsApp</span>
      </Menu.Item>
      <Menu.Item key="viber" onClick={onAddChannelClick(Channels.VIBER)}>
        <div className="channel-icon">
          <SbChannelIcon size="small" type={Channels.VIBER} />
        </div>
        Viber
      </Menu.Item>
      <Menu.Item key="elma-chat-desk" onClick={onAddChannelClick(Channels.ELMA365)}>
        <div className="channel-icon">
          <SbChannelIcon size="small" type={Channels.ELMA365} />
        </div>
        Линия ELMA&nbsp;365
      </Menu.Item>
      <Flags authorizedFlags={[FeatureFlagNames.HIDDEN]}>
        <Menu.Item key="web-chat" onClick={onAddChannelClick(Channels.WEBCHAT)}>
          <div className="channel-icon">
            <SbChannelIcon size="small" type={Channels.WEBCHAT} />
          </div>
          Webchat
        </Menu.Item>
      </Flags>
    </Menu>
  );

  return (
    <div className="sb-add-channel-button">
      <SbContextMenu menuContent={addChannelMenuContent}>
        <SbButton sbSize="medium" sbType={buttonType}>
          <SbIcon iconName="plus" size={20} />
          Добавить
        </SbButton>
      </SbContextMenu>
    </div>
  );
};

export default AddChannelButton;
