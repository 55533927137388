import React, { useEffect, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useSetRecoilState } from 'recoil';
import { Popover, Select, Skeleton, Table } from 'antd';
import { SelectValue } from 'antd/lib/select';
import moment, { Moment } from 'moment';
import { useDebounce } from 'usehooks-ts';
import { useHistory } from 'react-router-dom';
import { useAsync } from 'react-async-hook';

import SbDatePicker from '../../../components/common/SbDatePicker';
import SbSelect from '../../../components/common/SbSelect';
import SbButton from '../../../components/common/SbButton';
import SbScroll from '../../../components/common/SbScroll';
import SbInput from '../../../components/common/SbInput';
import SbIcon from '../../../components/common/SbIcon';
import { ConversationSortDirection, ConversationStatus } from '../../../../../api';
import { ChannelNames, ConversationStatusNames, DialogItem } from '../../../utils/dialogs';
import { alertsSelectorAdd } from '../../../../recoil/alerts';
import { AlertTypes, DIALOGS_EXPORT_FINISHED } from '../../../../constants';
import { conversationApi, conversationExportApi } from '../../../../apis';
import { useQuery } from '../../../../utils/urlUtil';
import { hubConnections } from '../../../../utils/socketsUtil';
import SbTypography from '../../../components/common/SbTypography';
import SbPanel from '../../../components/common/SbPanel';
import SbModal from '../../../components/common/SbModal';

import DialogListItem from './DialogListItem';

const DIALOG_ITEMS_SCROLL_ID = 'sb-dialog-items-scroll';
const DATE_LOCAL_FORMAT = 'DD.MM.YYYY';
const DATE_FORMAT = 'YYYY-MM-DD';
const SEARCH_DELAY = 200; //ms
const CONVERSATION_SEARCH_PAGE_SIZE = 100;

const CHANNEL_PARAM = 'channel';
const CONVERSATION_STATUS_PARAM = 'conversationStatus';
const START_FROM_DATE_PARAM = 'startFromDate';
const START_TO_DATE_PARAM = 'startToDate';
const LATEST_MESSAGE_FROM_DATE_PARAM = 'latestMessageFromDate';
const LATEST_MESSAGE_TO_DATE_PARAM = 'latestMessageToDate';
const CONVERSATION_ID_PARAM = 'conversationId';
const SEARCH_PARAM = 'query';

interface IDialogFilter {
  channel?: string;
  status?: ConversationStatus;
  startDateRange?: [Moment, Moment];
  lastMessageDateRange?: [Moment, Moment];
}

interface IDialogsExport {
  preparing: boolean;
  requestId: string;
  errorMessage: string;
  fileUrl: string;
}

const dialogsExportDefaultValue: IDialogsExport = {
  preparing: false,
  requestId: '',
  errorMessage: '',
  fileUrl: '',
};

interface IDialogListProps {
  agentStageId?: string;
}

const DialogList: React.FC<IDialogListProps> = ({ agentStageId }) => {
  const addAlert = useSetRecoilState(alertsSelectorAdd);
  const { replace } = useHistory();
  const query = useQuery();

  const [conversationsPageIndex, setConversationsPageIndex] = useState(0);
  const [selectedConversationId, setSelectedConversationId] = useState<string>();
  const [conversations, setConversations] = useState([] as DialogItem[]);
  const [hasMore, setHasMore] = useState(false);
  const [loading, setLoading] = useState(false);
  const [filterPopoverVisible, setFilterPopoverVisible] = useState(false);
  const [filter, setFilter] = useState<IDialogFilter>({});
  const [searchText, setSearchText] = useState('');
  const [selectAll, setSelectAll] = useState(false);

  const [dialogsExport, setDialogsExport] = useState(dialogsExportDefaultValue);
  const [exportModalVisible, setExportModalVisible] = useState(false);

  const debouncedSearchText = useDebounce(searchText, SEARCH_DELAY);
  const deferredSearchText = searchText ? debouncedSearchText : '';
  const selectedDialogs = conversations.filter((c) => c.isSelected).map((i) => i.model.id);

  const { result: conn } = useAsync(hubConnections.getBotManagerConnection, []);

  const closeExportReportModal = () => {
    setExportModalVisible(false);
    setDialogsExport(dialogsExportDefaultValue);
  };

  const runExportDialogsAsync = async () => {
    if (!agentStageId) {
      return;
    }

    setDialogsExport({
      ...dialogsExportDefaultValue,
      preparing: true,
    });
    setExportModalVisible(true);

    try {
      const response = await conversationExportApi.runConversationExportByFilter({
        ids: selectedDialogs,
      });

      setDialogsExport({
        ...dialogsExportDefaultValue,
        requestId: response.data.requestId,
        preparing: true,
      });
    } catch (e) {
      addAlert({
        type: AlertTypes.ERROR,
        message: 'Ошибка при запуске экспорта данных аналитики',
        error: e,
      });
      closeExportReportModal();
    }
  };

  const dialogsExportEventHandler = (args: { requestId: string; fileUrl: string; errorMessage: string }) => {
    if (dialogsExport.requestId !== args?.requestId || !dialogsExport.preparing) {
      return;
    }

    const fileUrl = args?.fileUrl || '';
    setDialogsExport({
      ...dialogsExport,
      preparing: false,
      errorMessage: args?.errorMessage || '',
      fileUrl,
    });

    if (fileUrl) {
      // eslint-disable-next-line security/detect-non-literal-fs-filename
      window.open(fileUrl, '_self');
    }
  };

  const subscribeToDialogsExportEvents = () => {
    if (!dialogsExport.requestId || !conn) return;

    conn.on(DIALOGS_EXPORT_FINISHED, dialogsExportEventHandler);

    return () => {
      conn.off(DIALOGS_EXPORT_FINISHED, dialogsExportEventHandler);
    };
  };
  useEffect(subscribeToDialogsExportEvents, [conn, dialogsExport.requestId]);

  const loadDialogs = async (loadMore: boolean, filter: IDialogFilter) => {
    if (loading || !agentStageId) return;

    setLoading(true);
    try {
      const sorting = ConversationSortDirection.FinishedOnDescending;
      const conversationsResponse = await conversationApi.searchConversations(
        filter.channel,
        agentStageId,
        undefined,
        undefined,
        filter.status,
        filter.startDateRange?.[0].format(DATE_FORMAT),
        filter.startDateRange?.[1].format(DATE_FORMAT),
        filter.lastMessageDateRange?.[0].format(DATE_FORMAT),
        filter.lastMessageDateRange?.[1].format(DATE_FORMAT),
        deferredSearchText,
        sorting,
        loadMore ? conversationsPageIndex : 0,
        CONVERSATION_SEARCH_PAGE_SIZE
      );

      const result: DialogItem[] = (conversationsResponse.data.items ?? []).map((c) => ({
        isSelected: false,
        model: c,
      }));
      if (loadMore) {
        setConversations([...conversations, ...result]);
      } else {
        setConversations(result);
      }
      setConversationsPageIndex((conversationsResponse.data.pageIndex ?? conversationsPageIndex) + 1);
      setHasMore(conversationsResponse.data.hasMore ?? false);
    } catch (e) {
      addAlert({
        type: AlertTypes.ERROR,
        message: 'Ошибка при загрузке диалогов',
        error: e,
      });
    }
    setLoading(false);
  };

  const filterIsEmpty = !(filter.channel || filter.status || filter.startDateRange || filter.lastMessageDateRange);

  const initFilter = () => {
    const channel = query.get(CHANNEL_PARAM);
    const status = query.get(CONVERSATION_STATUS_PARAM);
    const startFromDate = query.get(START_FROM_DATE_PARAM);
    const startToDate = query.get(START_TO_DATE_PARAM);
    const latestMessageFromDate = query.get(LATEST_MESSAGE_FROM_DATE_PARAM);
    const latestMessageToDate = query.get(LATEST_MESSAGE_TO_DATE_PARAM);
    const searchText = query.get(SEARCH_PARAM);

    let newFilter = {};

    if (channel) {
      newFilter = {
        channel,
      };
    }

    if (status) {
      newFilter = {
        ...newFilter,
        status: status as ConversationStatus,
      };
    }

    if (startFromDate && startToDate) {
      newFilter = {
        ...newFilter,
        startDateRange: [moment(startFromDate), moment(startToDate)],
      };
    }

    if (latestMessageFromDate && latestMessageToDate) {
      newFilter = {
        ...newFilter,
        lastMessageDateRange: [moment(latestMessageFromDate), moment(latestMessageToDate)],
      };
    }

    searchText && setSearchText(searchText);

    setFilter(newFilter);
    loadDialogs(false, newFilter).finally();
  };
  useEffect(initFilter, [agentStageId, deferredSearchText]);

  const initSelectedConversation = () => {
    const conversationId = query.get(CONVERSATION_ID_PARAM);
    conversationId && setSelectedConversationId(conversationId);
  };
  useEffect(initSelectedConversation, []);

  const onItemSelection = (item: DialogItem) => {
    item.isSelected = !item.isSelected;
    if (selectAll && !item.isSelected) {
      return setSelectAll(false);
    }
    if (conversations.every((c) => c.isSelected)) {
      setSelectAll(true);
    }
  };

  const dialogItem = [
    {
      render: (item: DialogItem) => <DialogListItem item={item} onSelection={() => onItemSelection(item)} />,
    },
  ];

  const onDialogSelect = (item: DialogItem) => {
    const newConversationId = selectedConversationId === item.model.id ? undefined : item.model.id;
    setSelectedConversationId(newConversationId);
    if (newConversationId) {
      query.set(CONVERSATION_ID_PARAM, newConversationId);
    } else {
      query.delete(CONVERSATION_ID_PARAM);
    }
    replace({
      search: query.toString(),
    });
  };

  const onSearchChange = async (value: string) => {
    setSearchText(value);
    if (value) {
      query.set(SEARCH_PARAM, value);
    } else {
      query.delete(SEARCH_PARAM);
    }
    replace({
      search: query.toString(),
    });
  };

  const onFilterPopoverVisibleChange = (visible: boolean) => setFilterPopoverVisible(visible);

  const onExportModalClose = () => closeExportReportModal();

  const onApplyFilter = async () => {
    await loadDialogs(false, filter);
    setFilterPopoverVisible(false);

    filter.channel && query.set(CHANNEL_PARAM, filter.channel);
    filter.status && query.set(CONVERSATION_STATUS_PARAM, filter.status);
    if (filter.startDateRange) {
      query.set(START_FROM_DATE_PARAM, filter.startDateRange?.[0].format(DATE_FORMAT));
      query.set(START_TO_DATE_PARAM, filter.startDateRange?.[1].format(DATE_FORMAT));
    }
    if (filter.lastMessageDateRange) {
      query.set(LATEST_MESSAGE_FROM_DATE_PARAM, filter.lastMessageDateRange?.[0].format(DATE_FORMAT));
      query.set(LATEST_MESSAGE_TO_DATE_PARAM, filter.lastMessageDateRange?.[1].format(DATE_FORMAT));
    }
    replace({
      search: query.toString(),
    });
  };

  const onResetFilter = async () => {
    setFilter({});
    await loadDialogs(false, {});

    query.delete(CHANNEL_PARAM);
    query.delete(CONVERSATION_STATUS_PARAM);
    query.delete(START_TO_DATE_PARAM);
    query.delete(START_FROM_DATE_PARAM);
    query.delete(LATEST_MESSAGE_TO_DATE_PARAM);
    query.delete(LATEST_MESSAGE_FROM_DATE_PARAM);
    replace({
      search: query.toString(),
    });
  };

  const onFilterChannelChange = (value: SelectValue) =>
    setFilter({
      ...filter,
      channel: value as string,
    });

  const onFilterStatusChange = (value: SelectValue) =>
    setFilter({
      ...filter,
      status: value as ConversationStatus,
    });

  const onFilterStartDateChange = (values: [Moment | null, Moment | null] | null) =>
    setFilter({
      ...filter,
      startDateRange: values as [Moment, Moment],
    });

  const onFilterLastMessageDateChange = (values: [Moment | null, Moment | null] | null) =>
    setFilter({
      ...filter,
      lastMessageDateRange: values as [Moment, Moment],
    });

  const onRefreshButtonClick = async () => await onApplyFilter();

  const onDialogsExportButtonClick = () => runExportDialogsAsync().finally();

  const onDialogsSelectAllButtonClick = () => {
    const newConversations: DialogItem[] = [...conversations].map((c) => ({ isSelected: true, model: c.model }));
    setSelectAll(true);
    setConversations(newConversations);
  };

  const onDialogsDeselectAllButtonClick = () => {
    const newConversations: DialogItem[] = [...conversations].map((c) => ({ isSelected: false, model: c.model }));
    setSelectAll(false);
    setConversations(newConversations);
  };

  const filterMenuContent = (
    <div>
      <h3>Дата начала</h3>
      <SbDatePicker.Range
        format={DATE_LOCAL_FORMAT}
        picker="date"
        value={filter.startDateRange}
        onChange={onFilterStartDateChange}
      />
      <h3>Дата последнего сообщения</h3>
      <SbDatePicker.Range
        format={DATE_LOCAL_FORMAT}
        picker="date"
        value={filter.lastMessageDateRange}
        onChange={onFilterLastMessageDateChange}
      />
      <h3>Канал</h3>
      <SbSelect sbType="light" value={filter.channel} onChange={onFilterChannelChange}>
        {ChannelNames.map((c) => (
          <Select.Option key={c.value} value={c.value}>
            {c.label}
          </Select.Option>
        ))}
      </SbSelect>
      <h3>Статус</h3>
      <SbSelect sbType="light" value={filter.status} onChange={onFilterStatusChange}>
        {ConversationStatusNames.map((s) => (
          <Select.Option key={s.value} value={s.value}>
            {s.label}
          </Select.Option>
        ))}
      </SbSelect>
      <div className="sb-dialogs-card__content__list-container__filter-menu__buttons">
        <SbButton sbSize="medium" onClick={onApplyFilter}>
          Показать
        </SbButton>
        <SbButton sbSize="medium" sbType="secondary" onClick={onResetFilter}>
          Сбросить фильтр
        </SbButton>
      </div>
    </div>
  );

  const renderFilterSuffix = () => {
    return (
      <Popover
        destroyTooltipOnHide
        align={{ offset: [8, 8] }}
        content={filterMenuContent}
        overlayClassName="sb-dialogs-card__content__list-container__filter-menu"
        placement="bottomRight"
        trigger={['click']}
        visible={filterPopoverVisible}
        onVisibleChange={onFilterPopoverVisibleChange}
      >
        <SbIcon className={!filterIsEmpty ? 'badge' : ''} iconName="filter" />
      </Popover>
    );
  };

  return (
    <>
      <div className="sb-dialogs-card__content__list-container__filter">
        <SbInput
          customSuffix={renderFilterSuffix()}
          placeholder="Поиск"
          sbSize="small"
          value={searchText}
          onChange={onSearchChange}
        />
        <SbButton icon={<SbIcon iconName="redo" />} loading={loading} sbSize="medium" onClick={onRefreshButtonClick} />
      </div>
      <div className="sb-dialogs-card__content__list-container__list">
        <SbScroll id={DIALOG_ITEMS_SCROLL_ID}>
          <InfiniteScroll
            dataLength={conversations.length}
            hasMore={hasMore}
            loader={<Skeleton active avatar />}
            next={() => loadDialogs(true, filter)}
            scrollableTarget={DIALOG_ITEMS_SCROLL_ID}
          >
            <Table
              columns={dialogItem}
              dataSource={[...conversations]}
              locale={{
                emptyText: loading && <Skeleton active avatar />,
              }}
              pagination={false}
              rowKey="id"
              rowSelection={{ selectedRowKeys: [selectedConversationId || ''] }}
              showHeader={false}
              onRow={(item) => {
                return {
                  onClick: () => {
                    onDialogSelect(item);
                  },
                };
              }}
            />
          </InfiniteScroll>
        </SbScroll>
      </div>
      {!!selectedDialogs.length && (
        <div className="sb-dialogs-card__content__list-container__export">
          <div className="sb-dialogs-card__content__list-container__export__child">
            <SbIcon iconName="check-one" />
            {selectedDialogs.length}
          </div>
          <div className="sb-dialogs-card__content__list-container__export__child">
            {!selectAll ? (
              <SbButton sbSize="medium" sbType="link" onClick={onDialogsSelectAllButtonClick}>
                Выбрать все
              </SbButton>
            ) : (
              <SbButton sbSize="medium" sbType="link" onClick={onDialogsDeselectAllButtonClick}>
                Снять выделение
              </SbButton>
            )}
            <SbButton sbSize="medium" sbType="link" onClick={onDialogsExportButtonClick}>
              Экспорт
            </SbButton>
          </div>
        </div>
      )}
      <SbModal
        footer={[
          <SbButton key="cancel" sbSize="medium" sbType="secondary" onClick={onExportModalClose}>
            Закрыть
          </SbButton>,
        ]}
        sbSize="small"
        title="Экспорт истории диалогов"
        visible={exportModalVisible}
        width={600}
        onCancel={onExportModalClose}
      >
        <SbTypography>
          {dialogsExport.preparing ? (
            <SbTypography>
              <h4>Пожалуйста, подождите, происходит экспорт выбранных диалогов...</h4>
            </SbTypography>
          ) : dialogsExport.errorMessage ? (
            <SbPanel sbType="help">
              <SbTypography>
                <p>{dialogsExport.errorMessage}</p>
              </SbTypography>
            </SbPanel>
          ) : (
            <SbTypography>
              <h4>Выбранные диалоги успешно экспортированы!</h4>
              <a href={dialogsExport.fileUrl}>Ссылка на скачивание</a>
            </SbTypography>
          )}
        </SbTypography>
      </SbModal>
    </>
  );
};

export default DialogList;
